import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../components/Carousel/Carousel';
import IntroPage from '../../components/ContentType/IntroPage/IntroPage';
import PageBanner from '../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../components/Seo/Seo';
import SliderComponent from '../../components/SliderComponent/SliderComponent';
import Layout from '../../Layout';
import { Button } from '../../Molecules/Button/Button';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../Molecules/TitleSection/TitleSection';

import './styles.scss';
let classNames = require('classnames');

const EngagementEnjeux = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          mobile: fixed(width: 456, height: 456) {
            ...GatsbyImageSharpFixed
          }
          desktop: fluid {
            ...GatsbyImageSharpFluid
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const slides = [
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        link: '/',
        link_label: 'Afficher',
      },
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        link: '/',
        link_label: 'Afficher',
      },
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        link: '/',
        link_label: 'Afficher',
      },
    },
  ];

  slides.push(...slides);

  const slider_settings = {
    className: 'arrow_bottom_slider',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: false
      }
    }]
  };

  return (
    <Layout>
      <Seo title="Colas Engagement Enjeux" description="Les enjeux" />
      <div className={classNames('page_template', 'page_engagement_enjeux')}>
        <ScrollPageNav
          data={[
            {
              title: 'Inventer une mobilité plus durable',
              section: 'block_0_section'
            },
            {
              title: 'Plus de mobilité, moins d’impact',
              section: 'block_1_section'
            },
            {
              title: 'La mobilité au service des territoires :',
              section: 'block_2_section'
            },
            {
              title: 'L’espace public, lieu de partage',
              section: 'block_3_section'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: 'engagement', url: '/engagements' },
                currentPage: {
                  title: 'Les enjeux',
                  url: '/enjeux',
                },
              }}
            />
            <TitlePage
              color="color_white"
              title="INNOVATION"
              description="<p>Une force d’innovation tout entière orientée vers la mobilité responsable<p>"
            />
          </div>
        </PageBanner>
        <IntroPage
          customClass="block_0_section"
          title="Inventer une<br/> mobilité plus<br/> durable"
          subtitle=""
          visuel={data.file.childImageSharp}
          text="<p>La mobilité recouvre à la fois les moyens de déplacement, l’infrastructure et le mode de transport utilisés. Inventer une mobilité plus durable est un défi. Désenclaver les zones rurales, développer les territoires, préserver l’environnement, autant de nécessités qui exigent de repenser les infrastructures de transport et d’accompagner l’émergence de nouvelles solutions de mobilité. L’innovation est au cœur de l’ADN de Colas depuis plus de 90 ans. L’esprit pionnier qui anime ses collaborateurs est le véritable moteur du Groupe. Innover dans la mobilité, c’est la considérer en permanence comme un service rendu, aux hommes comme à la planète. C’est faire en sorte que le développement de l’un ne mette pas en péril l’avenir de l’autre. C’est faire de nos réponses des moyens d’avancer, ensemble, dans la bonne direction.</p>"
          size="border" //wrapper
        />
        <section className={classNames('section_content', 'block_1_section')}>
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="wrapper_page_xs">
              <TitleSection
                title="Plus de mobilité, moins d’impact"
                type="arrow"
              />
              <h5>Plus de mobilité, moins d’impact</h5>
              <p>
                Donner à tous les moyens d’avancer, ce n’est pas favoriser une
                mobilité effrénée, toujours plus rapide, partout, tout le temps.
                C’est avant tout préserver les ressources, les territoires, les
                écosystèmes dans lesquels et avec lesquels nous vivons et nous
                déplaçons. Il n’y a pas d’avenir pour la mobilité sans
                responsabilité et sobriété. Il ne faut pas une mobilité plus
                forte mais une mobilité plus juste et plus vertueuse. La
                question à laquelle l’innovation doit répondre est comment
                bouger mieux ? En limitant au maximum l’impact et les nuisances
                de nos déplacements, en faisant appel à de nouveaux matériaux,
                en développant la maintenance prédictive pour augmenter la
                durabilité de nos infrastructures, ou en travaillant à la
                recyclabilité de nos solutions.
              </p>
            </div>
            <div className={classNames('only_mobile_tablet')}>
              <SliderComponent settings={slider_settings}>
                {slides.map((slide, i) => (
                  <div key={i}>
                    <div className="only_mobile">
                      {slide?.image &&
                        <Img
                          style={{ width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                        />
                      }
                    </div>
                    <div className="only_tablet">
                      {slide?.image &&
                        <Img
                          style={{ height: '100%', width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.desktop, aspectRatio: 1 }}
                        />
                      }
                    </div>
                    <div className="info_title_description">
                      <p>{slide.info.text}</p>
                      {slide.info.link && (
                        <Button
                          primary={true}
                          label={slide.info.link_label}
                          link={slide.info.link}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </SliderComponent>
            </div>
            <div className="wrapper_page_xs">
              <div className={classNames('only_desktop')}>
                <Carousel slides={slides} sliderMode="" />
              </div>
            </div>
          </ShapeBackground>
        </section>

        <section className={classNames(
          'section_content',
          'z-index-top',
          'block_2_section'
        )}
        >
          <ShapeBackground top="left" color="dark_bleu" bottom="left">
            <div className="wrapper_page_xs">
              <TitleSection
                title="La mobilité au service des territoires :"
                type="arrow"
                h2color="white"
                h4color="white"
                className="color_white"
              />
              <h5 className="color_white">Créer un écosystème pour favoriser et diffuser l’innovation</h5>
              <p>
                Innover pour la mobilité, c’est en faire un support du
                développement des territoires. La mobilité relie, permet,
                égalise. À nous d’inventer des solutions pour réaliser les
                ambitions locales, nationales ou globales. La mobilité évolue
                aussi avec son temps : elle accompagne l’émergence de nouveaux
                usages, de nouvelles façons de vivre, de penser le rapport à
                l’espace. Les infrastructures de transport doivent être des
                solutions, pas des contraintes, pour permettre d’envisager
                réellement la mobilité comme un service, intégrant un maximum de
                possibilités et permettant à un territoire de se développer et
                ceux qui le font vivre de s’y épanouir. C’est une question
                d’utilité. C’est pourquoi nous n’envisageons pas l’innovation
                comme un vase clos centralisé mais comme une philosophie
                partagée et liée à notre compréhension des spécificités locales.
              </p>
            </div>
            <div className={classNames('only_mobile_tablet')}>
              <SliderComponent settings={slider_settings} arrowColor="color_white">
                {
                  slides.map((slide, index) => (
                    <div key={index}>
                      <div className="only_mobile">
                        {slide?.image &&
                          <Img
                            style={{ width: '100%' }}
                            imgStyle={{ objectFit: 'cover' }}
                            fluid={{ ...slide.image.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                          />
                        }
                      </div>
                      <div className="only_tablet">
                        {slide?.image &&
                          <Img
                            style={{ height: '100%', width: '100%' }}
                            imgStyle={{ objectFit: 'cover' }}
                            fluid={{ ...slide.image.childImageSharp.desktop, aspectRatio: 1 }}
                          />
                        }
                      </div>
                      <div className={classNames('info_title_description', 'color_white')}>
                        <p>{slide.info.text}</p>
                        {slide.info.link && (
                          <Button
                            primary={true}
                            label={slide.info.link_label}
                            link={slide.info.link}
                          />
                        )}
                      </div>
                    </div>
                  ))
                }
              </SliderComponent>
            </div>
            <div className="wrapper_page_xs">
              <div className={classNames('only_desktop')}>
                <Carousel slides={slides} sliderMode="" textColor="color_white" />
              </div>
            </div>
          </ShapeBackground>
        </section>

        <section className={classNames('section_content', 'block_3_section')}>
          <div className={classNames('wrapper_page_xs')}>
            <TitleSection
              title="L’espace public, lieu de partage"
              type="arrow"
            />
            <h5>Accompagner le développement local grâce à la « smart road »</h5>
            <p>
              Innover, c’est accepter de se remettre en question, d’être
              challengé et de s’ouvrir à tout ce qui peut nous faire grandir.
              L’espace public et la route sont par essence le lieu de la
              rencontre et du partage. Nous avons donc naturellement adopté une
              approche de l’innovation qui lui ressemble : ouverte, partagée,
              dans une logique d’écosystème d’acteurs et une mentalité de « work
              in progress » collective et permanente. Nous soutenons et
              encourageons l’open innovation, participons à des projets
              collectifs pour améliorer ce bien commun qu’est l’espace public et
              faire de la mobilité une aventure partagée.
            </p>
          </div>
          <div className={classNames('only_mobile_tablet')}>
            <SliderComponent settings={slider_settings}>
              {
                slides.map((slide, index) => (
                  <div key={index}>
                    <div className="only_mobile">
                      {slide?.image &&
                        <Img
                          style={{ width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                        />
                      }
                    </div>
                    <div className="only_tablet">
                      {slide?.image &&
                        <Img
                          style={{ height: '100%', width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.desktop, aspectRatio: 1 }}
                        />
                      }
                    </div>
                    <div className="info_title_description">
                      <p>{slide.info.text}</p>
                      {slide.info.link && (
                        <Button
                          primary={true}
                          label={slide.info.link_label}
                          link={slide.info.link}
                        />
                      )}
                    </div>
                  </div>
                ))
              }
            </SliderComponent>
          </div>
          <div className={classNames('wrapper_page_xs')}>
            <div className={classNames('only_desktop')}>
              <Carousel slides={slides} sliderMode="" textColor="color_white" />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default EngagementEnjeux;
